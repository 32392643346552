import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserInfo } from 'src/app/core/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageService {

  public adminLogs: BehaviorSubject<ManagerLog[]> = new BehaviorSubject<ManagerLog[]>([]);

  constructor(private http: HttpClient) { }

  public async loadData() {
    this.adminLogs.next(
      await this.getAdminLogs().toPromise()
    )
  }

  public getAdminUsers() {
    return this.http.get<UserInfo[]>(`${environment.backend}/api/Admin/AdminUsers`, { headers: {"contentType": "application/json" }});
  }

  public updateAdminUser(user: UserInfo) {
    return this.http.put(`${environment.backend}/api/Admin/UpdateAdminUser`, user, { headers: {"contentType": "application/json" }});
  }

  public addAdminUser(user: UserInfo) {
    return this.http.post(`${environment.backend}/api/Admin/AddAdminUser`, user, { headers: {"contentType": "application/json" }});
  }

  public deleteAdminUser(userId: string) {
    return this.http.delete(`${environment.backend}/api/Admin/DeleteAdminUser?userId=${userId}`, { headers: {"contentType": "application/json" }});
  }

  public getAdminLogs() {
    return this.http.get<ManagerLog[]>(`${environment.backend}/api/Admin/AdminLogs`, { headers: {"contentType": "application/json" }});
  }

  public getAdminLogsByAction(action: string) {
    return this.http.get<ManagerLog[]>(`${environment.backend}/api/Admin/AdminLogsByAction?action=${action}`, { headers: {"contentType": "application/json" }});
  }
}

export interface ManagerLog {
  id?: string;
  operatorID?: string;
  action?: string;
  date?: string;
  receiverID?: number;
  receiverCharID?: number;
  receiverName?: string;
}