import { LoginService } from './../../../services/login.service';
import { GridService } from './../../../services/grid.service';
import { SwaggerService } from './../../../services/swagger.service';
import { OnlinePlayerService } from './../../../services/online-player.service';
import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { BanplayerComponent } from '../dialogs/banplayer/banplayer.component';
import { MovePlayerDto } from 'src/app/swagger';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { filter } from 'rxjs/internal/operators/filter';

interface AdminDisplay {
  dwUserID?: number;
  dwCharID?: number;
  szNAME?: string;
  bLevel?: number;
  bClass?: number;
  bCountry?: number;
}

interface CharDisplay {
  dwGold?: number;
  dwSilver?: number;
  dwCooper?: number;
  wSkillPoint?: number;
  ehre?: number;
  bLuckyNumber?: number;
  szLoginIP?: string;
}



@Component({
  selector: 'app-online-player',
  templateUrl: './online-player.component.html',
  styleUrls: ['./online-player.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '58px', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OnlinePlayerComponent implements OnInit, OnDestroy {

  public gridData: Array<AdminDisplay> = [];
  public displayGridData: Array<AdminDisplay> = [];
  public displayedColumns: Array<string> = ['UserID', 'CharID', 'CharName', 'Level', 'Class', 'Country', 'actions'];
  public toExpandElement: AdminDisplay | undefined;
  public charData: CharDisplay;
  public displayCharColumns: Array<string> = ['Gold', 'Silver', 'Cooper', 'SkillPoints', 'LuckyNumber', 'LoginIP'];
  private filter: string = "";
  private sub: Subscription = new Subscription;

  constructor(private opService: OnlinePlayerService,
              public dialog: MatDialog, 
              public gridService: GridService,
              private authSvc: AuthService) {
    this.sub.add(this.opService.onlinePlayers.pipe(filter(x => x != null)).subscribe(data => {
      this.gridData = [];
      data.forEach(d => {
        if (d && d.character) {
          this.gridData.push({
            dwUserID: d.user.dwUserID,
            dwCharID: d.user.dwCharID,
            szNAME: d.character.szNAME,
            bLevel: d.character.bLevel,
            bClass: d.character.bClass,
            bCountry: d.character.bCountry
          })
        }
      })
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    // this.loginSvc.checkIfLogin();
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
      this.gridService.reset();
      this.opService.loadData();
    }));
  }

  public async showDetails(row: AdminDisplay, index: number) {
    this.toExpandElement = this.toExpandElement === row ? null : row;
    if (this.toExpandElement != null) {
      let charData = this.opService.getCharacter(row.dwCharID);
      let userData = this.opService.getUser(row.dwUserID);
      this.charData = {
        dwGold: charData.dwGold,
        dwSilver: charData.dwSilver,
        dwCooper: charData.dwCooper,
        wSkillPoint: charData.wSkillPoint,
        bLuckyNumber: userData.bLuckyNumber,
        szLoginIP: userData.szLoginIP
      };
    }
  }

  public chatban(element) {
    //ToDo
  }

  public move(element) {
    const dialogRef = this.dialog.open(Moveplayerdialog, {
      width: '400px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result)
    });
  }

  public ban(element) {
    const dialogRef = this.dialog.open(BanplayerComponent, {
      width: '400px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result)
    });
  }

  public getClassName(bClass: number): string {
    switch(bClass) {
      case 0: return 'Warrior';
      case 1: return 'Assasin';
      case 2: return 'Archer';
      case 3: return 'Mage';
      case 4: return 'Priest';
      case 5: return 'Necromancer';
    }
  }

  public refresh() {
    this.opService.loadData();
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }
}

@Component({
  selector: 'moveplayerdialog',
  templateUrl: 'moveplayerdialog.html',
})
export class Moveplayerdialog {

  display: Array<string> = ['PosX', 'PosY', 'PosZ'];
  data: MovePlayerDto = { dwCharID: 0, fPosX: 0, fPosY: 0, fPosZ: 0 };

  constructor(
    public dialogRef: MatDialogRef<Moveplayerdialog>,
    @Inject(MAT_DIALOG_DATA) public element: AdminDisplay,
    private swagger: SwaggerService) { }

  onNoClick(): void {
    console.log("close")
    this.dialogRef.close();
  }

  async onSaveClick() {
    // this.data.dwCharID = this.element.dwCharID;
    // await this.swagger.admin.apiAdminMovePlayerPost(this.data).toPromise();
    // this.dialogRef.close();
  }
}