import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { GridService } from 'src/services/grid.service';
import { ManagerLog, ManageService } from 'src/services/manage.service';

export interface LogAction {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, OnDestroy {

  gridData: Array<ManagerLog> = []
  displayGridData: Array<ManagerLog> = []
  private filter = "";
  public hide: boolean = true;
  public displayedColumns: Array<string> = ['Agent', 'Action', 'Date', 'Description', 'ReceiverID', 'ReceiverCharID'];
  public actions: string[] = ["ALL", "PLAYER_BANNED", "PLAYER_UNBANNED", "AGENT_ADDED", "AGENT_UPDATED", "AGENT_DELETED", "COUPON_CREATED", "COUPON_UPDATED", "COUPON_DELETED", "CASH_REWARDS_UPDATED"];

  private sub: Subscription = new Subscription();

  constructor(private manageSvc: ManageService, public gridService: GridService) {
    this.sub.add(this.manageSvc.adminLogs.pipe(filter(x => x != null)).subscribe(data => {
      this.gridData = [];
      data.forEach(d => {
        this.gridData.push({
          id: d.id,
          action: d.action,
          date: d.date,
          operatorID: d.operatorID,
          receiverCharID: d.receiverCharID,
          receiverID: d.receiverID,
          receiverName: d.receiverName
        })
      });
      this.gridData = this.gridData.sort((a, b) => {
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
          return -1;
        }
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return 1;
        }
        if (new Date(a.date).getTime() === null) {
          return 1;
        }
        return 0;
      });
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.manageSvc.loadData();
  }

  public loadData() {
    this.manageSvc.loadData();
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public async getLogsByAction(event) {
    if(event === 'ALL') {
      this.manageSvc.loadData();
    }
    else {
      const logs: ManagerLog[] = await this.manageSvc.getAdminLogsByAction(event).toPromise();
      this.gridData = [];
      logs.forEach(d => {
        this.gridData.push({
          id: d.id,
          action: d.action,
          date: d.date,
          operatorID: d.operatorID,
          receiverCharID: d.receiverCharID,
          receiverID: d.receiverID,
          receiverName: d.receiverName
        })
      });
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }
  }
}