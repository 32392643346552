import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

interface AccessLevelType {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-manage-adduser-dialog',
  templateUrl: './manage-adduser-dialog.component.html',
  styleUrls: ['./manage-adduser-dialog.component.scss']
})
export class ManageAdduserDialogComponent implements OnInit {

  public name: string = null;
  public password: string = null;
  public accessLevel: number = null;

  public newsfeedTypes: AccessLevelType[] = [
    {
      value: 1,
      viewValue: 'Admin'
    }, 
    {
      value: 2,
      viewValue: 'Manager'
    },
    {
      value: 3,
      viewValue: 'Game Master'
    }];

  constructor(public dialogRef: MatDialogRef<ManageAdduserDialogComponent>) { }

  ngOnInit() {
  }

  public addUser() {
    if(this.name != null && this.password != null && this.accessLevel != null) {
      this.dialogRef.close({name: this.name, password: this.password, accessLevel: this.accessLevel});
    }
  }
}