import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { ManageService } from 'src/services/manage.service';
import { SupportService, SupportTicket, SupportTicketMessage } from 'src/services/support.service';
import { SupportTicketDetailsEditComponent } from './support-ticket-details-edit/support-ticket-details-edit.component';

@Component({
  selector: 'app-support-ticket-details',
  templateUrl: './support-ticket-details.component.html',
  styleUrls: ['./support-ticket-details.component.scss']
})
export class SupportTicketDetailsComponent implements OnInit {

  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;
  private sub: Subscription = new Subscription;
  public selectedTicket: SupportTicket;
  public ticketMessages: SupportTicketMessage[] = [];
  public foundTicket: boolean = false;
  public editorMessage: string = "";
  public currentUser: UserInfo = null;
  public adminUsers: UserInfo[] = [];

  constructor(private route: ActivatedRoute, 
              private supportSvc: SupportService, 
              private authSvc: AuthService, 
              private manageSvc: ManageService,
              public dialog: MatDialog) {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
      this.currentUser = token.userInfo;
    }));

    this.sub.add(this.route.params.subscribe(async params => {
      if (params['id'] != null) {
        this.selectedTicket = await this.supportSvc.getTicketByTicketId(params['id']).toPromise();
        await this.getMessagesByTicketId(this.selectedTicket.id);
        await this.getAdminUsers();
        this.foundTicket = true;
        //this.scrollToBottom();
      }
      else {
        console.log('No news found, redirect back!');
        this.foundTicket = false;
      }
    }));
  }

  ngOnInit() { }

  private async getMessagesByTicketId(ticketId: string) {
    try {
      const messages = await this.supportSvc.getMessagesByTicketId(ticketId).toPromise();
      this.ticketMessages = messages.sort((a, b) => {
        if(new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
        if(new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
        return 0;
      });
    }
    catch(e) {
      console.error(e);
    }
  }

  public async sendMessage() {
    if(this.editorMessage !== "") {
      try {
        const newMessage: SupportTicketMessage = {
          message: this.editorMessage,
          date: new Date().toISOString(),
          ticketId: this.selectedTicket.id,
          userId: 1,
          userName: this.currentUser.szName
        }
  
        const message = await this.supportSvc.writeTicketMessage(newMessage).toPromise();
        if(message != null) {
          this.editorMessage = "";
          this.selectedTicket.state = 2;
          this.ticketMessages.push(message);
          this.scrollToBottom();
        }
      }
      catch(e) {
        console.error(e);
      }
    }
  }

  public async getAdminUsers() {
    this.adminUsers = await this.manageSvc.getAdminUsers().toPromise();
  }

  public getLastModifierName(name: string): string {
    const foundAdmin = this.adminUsers.find(u => u.szID.toString() === name)
    if(foundAdmin) {
      return foundAdmin.szName;
    }
    else {
      return name;
    }
  }

  public scrollToBottom() {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  public getTicketCategory(category: string): string {
    const categories = this.supportSvc.ticketCategories;
    return categories.find(c => c.id === category).name;
  }

  public async closeTicket() {
    try {
      const result = await this.supportSvc.updateTicketState(this.selectedTicket.id, 3).toPromise();
      if(result && result.result) {
        this.selectedTicket.state = 3;
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  public async reopenTicket() {
    try {
      const result = await this.supportSvc.updateTicketState(this.selectedTicket.id, 0).toPromise();
      if(result && result.result) {
        this.selectedTicket.state = 0;
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  public async updatePriority() {
    try {
      console.log('isUrgent:', this.selectedTicket.isUrgent)
      const newPriority = this.selectedTicket.isUrgent === 1 ? 0 : 1;
      const result = await this.supportSvc.updateTicketPriority(this.selectedTicket.id, newPriority).toPromise();
      if(result) {
        this.selectedTicket.isUrgent = newPriority;
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(SupportTicketDetailsEditComponent, {
      width: '350px',
      data: {ticket: this.selectedTicket}
    });

    dialogRef.afterClosed().subscribe(async data => {
      if(data != null) {
        if(data.category != null && data.category !== this.selectedTicket.category) {
          try {
            const result = await this.supportSvc.updateTicketCategory(this.selectedTicket.id, data.category).toPromise();
            if(result && result === true) {
              this.selectedTicket.category = data.category;
            }
          }
          catch(e) { console.error(e); }
        }
        if(data.adminAssigned != null && data.adminAssigned !== this.selectedTicket.adminAssigned) {
          try {
            const result = await this.supportSvc.assignAdminToTicket(this.selectedTicket.id, data.adminAssigned).toPromise();
            if(result && result === true) {
              this.selectedTicket.adminAssigned = data.adminAssigned;
            }
          }
          catch(e) { console.error(e); }
        }
      }
    });
  }

  public getAssignedAdmin(adminId: string): string {
    if(adminId != null) {
      return "Assigned to " + this.adminUsers.find(u => u.szID.toString() === adminId).szName;
    }
    else {
      return "Not assigned"
    }
  }

  public getTicketMessageAuthor(author: string): string {
    return author === this.currentUser.szID ? this.currentUser.szName : author;
  }
}