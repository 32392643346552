import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SupportService {

  public tickets: BehaviorSubject<SupportTicket[]> = new BehaviorSubject<SupportTicket[]>([]);
  public onSelectedTicketChanged: BehaviorSubject<SupportTicket> = new BehaviorSubject<SupportTicket>(null);
  public ticketCategories: TicketCategory[] = [];

  constructor(private http: HttpClient) {
    this.getTicketCategories();
  }

  public async loadData() {
    this.tickets.next(
      await this.getTickets().toPromise()
    )
  }

  public getTickets(): Observable<SupportTicket[]> {
    return this.http.get<SupportTicket[]>(`${environment.backend}/api/Admin/Tickets`, {headers: {"contentType": "application/json"}});
  }

  public getTicketByTicketId(ticketId: string): Observable<SupportTicket> {
    return this.http.get<SupportTicket>(`${environment.backend}/api/Admin/TicketByTicketId?ticketId=${ticketId}`, {headers: {"contentType": "application/json"}});
  }

  public updateTicketState(ticketId: string, state: number): Observable<BackendResult> {
    return this.http.put<BackendResult>(`${environment.backend}/api/Admin/UpdateTicketState?ticketId=${ticketId}&state=${state}`, {headers: {"contentType": "application/json"}});
  }

  public updateTicketPriority(ticketId: string, priority: number): Observable<boolean> {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/UpdateTicketPriority?ticketId=${ticketId}&priority=${priority}`, {headers: {"contentType": "application/json"}});
  }

  public writeTicketMessage(newMessage: SupportTicketMessage): Observable<SupportTicketMessage> {
    return this.http.post<SupportTicketMessage>(`${environment.backend}/api/Admin/WriteTicketMessage`, newMessage, {headers: {"contentType": "application/json"}});
  }

  public getMessagesByTicketId(ticketId: string): Observable<SupportTicketMessage[]> {
    return this.http.get<SupportTicketMessage[]>(`${environment.backend}/api/Admin/MessagesByTicketId?ticketId=${ticketId}`, {headers: {"contentType": "application/json"}});
  }

  public async getTicketCategories() {
    this.ticketCategories = await this.http.get<TicketCategory[]>(`${environment.backend}/api/Account/TicketCategories`, {headers: {"contentType": "application/json"}}).toPromise();
  }

  public updateTicketCategory(ticketId: string, category: string) {
    return this.http.put(`${environment.backend}/api/Admin/UpdateTicketCategory?ticketId=${ticketId}&category=${category}`, {headers: {"contentType": "application/json"}});
  }

  public assignAdminToTicket(ticketId: string, admin: string) {
    return this.http.put(`${environment.backend}/api/Admin/AssignTicket?ticketId=${ticketId}&adminid=${admin}`, {headers: {"contentType": "application/json"}});
  }
}

export interface SupportTicket {
  id?: string;
  category?: string;
  title?: string;
  member?: string;
  lastModified?: string;
  lastModifiedByName?: string;
  inviteId?: string;
  createdBy?: number;
  createdByName?: string;
  state?: number;
  isUrgent?: number;
  createdAt?: string;
  adminAssigned?: string;
}

export interface SupportTicketMessage {
  id?: string;
  message?: string;
  userId?: number;
  userName?: string;
  date?: string;
  ticketId?: string;
}

export interface CreateTicketDto {
  category?: number;
  title?: string;
  message?: string;
}

export interface BackendResult {
  result: boolean;
  resultMessage: string;
}

export interface TicketCategory {
  id?: string;
  name?: string;
  isActive?: number;
}