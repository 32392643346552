import { Component, OnInit } from '@angular/core';
import { ServerStatusService } from 'src/services/server-status.service';

@Component({
  selector: 'app-server-status',
  templateUrl: './server-status.component.html',
  styleUrls: ['./server-status.component.scss']
})
export class ServerStatusComponent implements OnInit {

  public serverStatus: number;

  constructor(private serverStatusSvc: ServerStatusService) {
    this.getServerStatus();
  }

  ngOnInit() {
  }

  public async getServerStatus() {
    try {
      this.serverStatus = await this.serverStatusSvc.getServerStatus().toPromise();
    }
    catch(e) {
      console.error(e);
    }
  }

  public async updateServerStatus(event) {
    try {
      this.serverStatus = await this.serverStatusSvc.updateServerStatus(event.value).toPromise();
    }
    catch(e) {
      console.error(e);
    }
  }
}