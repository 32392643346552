import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { GridService } from 'src/services/grid.service';
import { ManageService } from 'src/services/manage.service';
import { SupportService, SupportTicket, TicketCategory } from 'src/services/support.service';

export interface TicketState {
  value: number;
  viewValue: string;
}

export interface TicketFilter {
  textFilter?: string;
  categoryFilter?: string;
  adminFilter?: string;
  stateFilter?: number;
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public isMobile;
  public screenSizeWatcher: Subscription;
  public isSidenavOpen: Boolean = true;
  public selectToggleFlag = false;
  public myTicketsView: SupportTicket[] = [];
  public myTickets: SupportTicket[] = [];
  private sub: Subscription = new Subscription;
  public currentUser: UserInfo = null;

  public adminUsers: UserInfo[] = [];
  public ticketCategories: TicketCategory[] = [];
  public ticketStates: TicketState[] = [
    {value: 0, viewValue: 'Open'},
    {value: 1, viewValue: 'Responded'},
    {value: 2, viewValue: 'Waiting for response'},
    {value: 3, viewValue: 'Closed'}
  ];

  public ticketFilter = {
    titleFilter: '',
    authorFilter: '',
    categoryFilter: '',
    adminFilter: '',
    stateFilter: null
  };

  public gridData: Array<SupportTicket> = []
  public displayGridData: Array<SupportTicket> = [];
  public hide: boolean = true;
  public displayedColumns: Array<string> = ['Title', 'Category', 'CreatedBy', 'CreatedAt', 'LastModified', 'adminAssigned', 'State', 'Action'];

  constructor(private router: Router,
              public composeDialog: MatDialog, 
              private supportSvc: SupportService, 
              private authSvc: AuthService, 
              public gridService: GridService,
              private manageSvc: ManageService) {
      this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
        this.currentUser = token.userInfo;
      }));

      this.loadFilterCategories();

      this.sub.add(this.supportSvc.tickets.pipe(filter(x => x != null)).subscribe(data => {
        
        this.gridData = [];
        data.forEach(d => {
          this.gridData.push({
            id: d.id,
            category: d.category,
            createdBy: d.createdBy,
            inviteId: d.inviteId,
            lastModified: d.lastModified,
            lastModifiedByName: d.lastModifiedByName,
            member: d.member,
            state: d.state,
            title: d.title,
            createdByName: d.createdByName,
            isUrgent: d.isUrgent,
            createdAt: d.createdAt,
            adminAssigned: d.adminAssigned
          });
        });
        this.displayGridData = this.gridData.filter(t => t.state !== 3);
        this.gridService.pagerHelper.length = this.displayGridData.length;
      }));
    }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.loadData();
  }

  public loadData() {
    this.supportSvc.loadData();
  }

  public filterTicketsByTitle(filterValue: string) {
    this.ticketFilter.titleFilter = filterValue.toLocaleLowerCase();
    this.filterTickets();
  }

  public filterTicketsByAuthor(filterValue: string) {
    this.ticketFilter.authorFilter = filterValue.toLocaleLowerCase();
    this.filterTickets();
  }

  public filterTicketsByCategory(category: string) {
    this.ticketFilter.categoryFilter = category;
    this.filterTickets();
  }

  public filterTicketsByAdmin(admin: string) {
    this.ticketFilter.adminFilter = admin === 'placeholder' ? '' : admin;
    this.filterTickets();
  }

  public filterTicketsByState(state: number) {
    this.ticketFilter.stateFilter = state;
    this.filterTickets();
  }

  public filterTickets() {
    let newDisplayedData: Array<SupportTicket> = this.gridData;

    if(this.ticketFilter.titleFilter !== "") {
      newDisplayedData = newDisplayedData.filter(x => x.title.toLocaleLowerCase().match(this.ticketFilter.titleFilter))
    }
    if(this.ticketFilter.authorFilter !== "") {
      newDisplayedData = newDisplayedData.filter(x => x.createdByName.toLocaleLowerCase().match(this.ticketFilter.authorFilter))
    }
    if(this.ticketFilter.categoryFilter !== "") {
      newDisplayedData = newDisplayedData.filter(x => x.category.toLocaleLowerCase().match(this.ticketFilter.categoryFilter))
    }
    if(this.ticketFilter.adminFilter !== "") {
      newDisplayedData = newDisplayedData.filter(x => {
        if(x.adminAssigned != null) {
          return x.adminAssigned.match(this.ticketFilter.adminFilter)
        }
      });
    }
    if(this.ticketFilter.stateFilter != null) {
      newDisplayedData = newDisplayedData.filter(x => x.state.toString().toLocaleLowerCase().match(this.ticketFilter.stateFilter.toString()))
    }

    this.displayGridData = newDisplayedData;
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, "");
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  public getTicketCategory(category: string): string {
    const categories = this.supportSvc.ticketCategories;
    return categories.find(c => c.id === category).name;
  }

  public viewTicket(ticket: SupportTicket) {
    this.router.navigate(['/ticket-details', ticket.id]);
  }

  public showOpenTickets() {
    this.clearFilters();
    this.displayGridData = this.gridData.filter(t => t.state !== 3);
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  public showClosedTickets() {
    this.clearFilters();
    this.displayGridData = this.gridData.filter(t => t.state === 3);
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  public showPriorityTickets() {
    this.clearFilters();
    this.displayGridData = this.gridData.filter(t => t.state !== 3 && t.isUrgent)
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  public showMyTickets() {
    this.clearFilters();
    this.displayGridData = this.gridData.filter(t => t.state !== 3 && t.adminAssigned === this.currentUser.szID);
    this.gridService.pagerHelper.length = this.displayGridData.length;
  }

  private clearFilters() {
    this.ticketFilter.titleFilter = "";
    this.ticketFilter.authorFilter = "";
    this.ticketFilter.categoryFilter = "";
    this.ticketFilter.adminFilter = "";
    this.ticketFilter.stateFilter = null;
  }

  private async loadFilterCategories() {
    const admins = await this.manageSvc.getAdminUsers().toPromise();
    admins.unshift({
      szID: "placeholder",
      szName: "-"
    });
    this.adminUsers = admins;

    this.ticketCategories = this.supportSvc.ticketCategories;
    this
  }

  public getAssignedAdmin(adminId: string): string {
    if(adminId != null) {
      return this.adminUsers.find(u => u.szID.toString() === adminId).szName;
    }
    else {
      return "-"
    }
  }
}