import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  public coupons: BehaviorSubject<CouponDto[]> = new BehaviorSubject<CouponDto[]>([]);
  public onEditCoupon: BehaviorSubject<Coupon> = new BehaviorSubject<Coupon>(null);

  constructor(private http: HttpClient) { }

  public async loadData() {
    this.coupons.next(
      await this.getCoupons().toPromise()
    )
  }

  public getCoupons(): Observable<CouponDto[]> {
    return this.http.get<CouponDto[]>(`${environment.backend}/api/Admin/Coupons`, { headers: {"contentType": "application/json" }});
  }

  public createCoupon(coupon: CouponDto): Observable<CouponDto> {
    return this.http.post<CouponDto>(`${environment.backend}/api/Admin/CreateCoupon`, coupon, { headers: {"contentType": "application/json" }});
  }

  public updateCoupon(coupon: CouponDto): Observable<boolean> {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/UpdateCoupon`, coupon, { headers: {"contentType": "application/json" }});
  }

  public deleteCoupon(couponId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.backend}/api/Admin/RemoveCoupon?id=${couponId}`, { headers: {"contentType": "application/json" }});
  }
}

export interface Coupon {
  wID?: string;
  uCouponID?: string;
  wUserID?: number;
  creationTime?: string;
  isRedeemed?: boolean;
  wAmount?: number;
  usageLimit?: number;
  isCash?: boolean;
  redeemedCount?: number;
  items?: CouponItem[];
  isActive?: boolean;
}

export interface CouponDto {
  wID?: string;
  uCouponID?: string;
  wUserID?: number;
  creationTime?: string;
  isRedeemed?: boolean;
  wAmount?: number;
  usageLimit?: number;
  isCash?: boolean;
  redeemedCount?: number;
  items?: string;
  isActive?: boolean;
}

export interface CouponItem {
  itemId: number;
  count: number;
}