import { TCURRENTUSER } from './../app/swagger/model/tCURRENTUSER';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OnlinePlayer } from 'src/app/swagger/model/onlinePlayer';
import { HttpClient } from '@angular/common/http';
import { TCHARTABLE } from 'src/app/swagger';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnlinePlayerService {

  public onlinePlayers: BehaviorSubject<OnlinePlayer[]> = new BehaviorSubject<OnlinePlayer[]>(null);
  private onlinePlayersNonObs: OnlinePlayer[] = [];
  public onlineCharacters: TCHARTABLE[] = [];

  constructor(private http: HttpClient) { }

  public async loadData() {
    const onlineplayers = await this.getCurrentOnlinePlayers().toPromise();
    onlineplayers.forEach(x => {
      if(x.character != null)
        this.onlineCharacters.push(x.character)
    })
    this.onlinePlayersNonObs = onlineplayers;
    this.onlinePlayers.next(onlineplayers);
  }

  getUser(dwUserID: number): TCURRENTUSER {
    return this.onlinePlayers.getValue()[this.onlinePlayers.getValue().findIndex(op => op.user.dwUserID == dwUserID)].user;
  }

  getCharacter(charId: number): TCHARTABLE {
    return this.onlineCharacters.find(op => op.dwCharID === charId);
  }

  public getCurrentOnlinePlayers(): Observable<OnlinePlayer[]> {
    return this.http.get<OnlinePlayer[]>(`${environment.backend}/api/Admin/CurrentOnlinePlayer`, { headers: {"contentType": "application/json" }});
  }
}
