import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, PageEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/auth.service';
import { GridService } from '../../../services/grid.service';
import { LoginService } from '../../../services/login.service';
import { NewsfeedEntry, NewsfeedService } from '../../../services/newsfeed.service';
import { filter } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CreateNewsfeedDialogComponent } from '../dialogs/create-newsfeed-dialog/create-newsfeed-dialog.component';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class NewsfeedComponent implements OnInit, OnDestroy {

  public gridData: Array<NewsfeedEntry> = [];
  public displayGridData: Array<NewsfeedEntry> = [];
  public displayedColumns: Array<string> = ['Title', 'Created at', 'Author', 'action'];
  private filter: string = "";
  private sub: Subscription = new Subscription();
  public expandedElement: NewsfeedEntry | null;
  public newsfeedTypes: NewsfeedType[] = [
    {
      value: 0,
      viewValue: 'Announcement'
    }, 
    {
      value: 1,
      viewValue: 'Maintenance'
    }, 
    {
      value: 2,
      viewValue: 'Alert'
    }];

  constructor(private newsfeedSvc: NewsfeedService,
    public dialog: MatDialog, public gridService: GridService,
    private authSvc: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer) {
    this.sub.add(this.newsfeedSvc.newsfeedEntries.subscribe(data => {
      this.gridData = [];
      data.forEach(d => this.gridData.push({
        id: d.id,
        title: d.title,
        message: d.message,  //this.sanitizer.bypassSecurityTrustHtml(d.message),
        date: d.date,
        status: d.status,
        author: d.author
      }));
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token !== null)).subscribe(token => {
      this.gridService.reset();
      this.newsfeedSvc.loadData();
    }));
  }

  public refresh() {
    this.newsfeedSvc.loadData();
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }

  public createNewsfeedEntry() {
    this.router.navigate(['/create-news'])


    // const dialogRef = this.dialog.open(CreateNewsfeedDialogComponent);

    // dialogRef.afterClosed().subscribe(async entry => {
    //   console.log('The dialog was closed');
    //   console.log(entry);
    //   if(entry != null) {
    //     try {
    //       const result = await this.newsfeedSvc.createNewsfeedEntry(entry).toPromise();
    //       if(result) {
    //         this.gridData.push(entry);
    //         this.displayGridData = this.gridData;
    //       }
    //     }
    //     catch(e) {
    //       console.error(e);
    //     }
    //   }
    // });
  }

  public async updateNewsfeedEntry(id: string, status: number, title: string, message: string, date: string) {
    try {
      console.log('id: ', id);
      const updatedEntry: NewsfeedEntry = {
        id: id,
        title: title,
        message: message,
        date: date,
        status: status
      }
      const result = await this.newsfeedSvc.updateNewsfeedEntry(updatedEntry).toPromise();
      console.log(result);
    }
    catch(e) {
      console.error(e);
    }
  }

  public async removeNewsfeedEntry(id: string) {
    try {
      const result = await this.newsfeedSvc.removeNewsfeedEntry(id).toPromise();
      if(result) {
        this.displayGridData = this.displayGridData.filter(entry => entry.id !== id);
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public editNewsfeedEntry(entry: NewsfeedEntry) {
    console.log(entry);
    this.newsfeedSvc.onEditNewsfeedEntry.next(entry);
    this.router.navigate(['/create-news', entry.id]);
  }

  openDetails(element) {

  }
}

export interface NewsfeedType {
  value: number;
  viewValue: string;
}