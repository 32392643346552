import { LoadingbarService } from './../services/loadingbar.service';
import { HttpintercepterService } from './../services/httpintercepter.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnlinePlayerComponent, Moveplayerdialog } from './views/online-player/online-player.component';
import { BanPlayerComponent } from './views/ban-player/ban-player.component';
import { UnbanPlayerComponent, Editdialog } from './views/unban-player/unban-player.component';
import { NavbarComponent } from './views/navbar/navbar.component';
import { BanplayerComponent } from './views/dialogs/banplayer/banplayer.component';
import { QuillModule } from 'ngx-quill'

import {
  MatTableModule, 
  MatPaginatorModule, 
  MatInputModule,
  MatButtonModule,
  MatDialogModule,
  MatCheckboxModule,
  MatIconModule,
  MatSelectModule,
  MatCardModule,
  MatListModule,
  MatButtonToggleModule,
  MatDatepicker,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatSidenavModule,
  MatChipsModule,
  MatSortModule
} from '@angular/material';
import { LoginComponent } from './views/login/login.component';
import { VersionsComponent } from './views/versions/versions.component';
import { NewsfeedComponent } from './views/newsfeed/newsfeed.component';
import { CreateNewsfeedDialogComponent } from './views/dialogs/create-newsfeed-dialog/create-newsfeed-dialog.component';
import { ServerStatusComponent } from './views/server-status/server-status.component';
import { SharedModule } from './shared/shared.module';
import { ManageComponent } from './views/manage/manage.component';
import { ManageAdduserDialogComponent } from './views/manage/manage-adduser-dialog/manage-adduser-dialog.component';
import { CouponsComponent } from './views/coupons/coupons.component';
import { CouponCreatEditComponent } from './views/coupons/coupon-creat-edit/coupon-creat-edit.component';
import { FinancialsComponent } from './views/financials/financials.component';
import { DonateRewardsComponent } from './views/financials/donate-rewards/donate-rewards.component';
import { LogsComponent } from './views/logs/logs.component';
import { EventsComponent } from './views/events/events.component';
import { EventDetailsComponent } from './views/events/event-details/event-details.component';
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';
import { FilterPipe } from './shared/pipes/filterPipe';
import { SupportComponent } from './views/support/support.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import { SupportTicketDetailsComponent } from './views/support/support-ticket-details/support-ticket-details.component';
import { SupportTicketDetailsEditComponent } from './views/support/support-ticket-details/support-ticket-details-edit/support-ticket-details-edit.component';


@NgModule({
  declarations: [
    AppComponent,
    OnlinePlayerComponent,
    BanPlayerComponent,
    UnbanPlayerComponent,
    NavbarComponent,
    Moveplayerdialog,
    Editdialog,
    BanplayerComponent,
    LoginComponent,
    VersionsComponent,
    NewsfeedComponent,
    CreateNewsfeedDialogComponent,
    ServerStatusComponent,
    ManageComponent,
    ManageAdduserDialogComponent,
    CouponsComponent,
    CouponCreatEditComponent,
    FinancialsComponent,
    DonateRewardsComponent,
    LogsComponent,
    EventsComponent,
    EventDetailsComponent,
    FilterPipe,
    SupportComponent,
    SupportTicketDetailsComponent,
    SupportTicketDetailsEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatRadioModule,
    MatCardModule,
    MatButtonToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatToolbarModule,
    MatChipsModule,
    MatMenuModule,
    MatExpansionModule,
    QuillModule.forRoot()
  ],
  providers: [
    HttpintercepterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpintercepterService,
      multi: true
    },
    LoadingbarService
  ],
  bootstrap: [AppComponent],
  entryComponents: [Moveplayerdialog, Editdialog, BanplayerComponent, ManageAdduserDialogComponent, SupportTicketDetailsEditComponent]
})
export class AppModule { }
