import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { EventsService, TEVENTCHART } from 'src/services/events.service';
import { GridService } from 'src/services/grid.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

  private sub: Subscription = new Subscription();

  gridData: Array<TEVENTCHART> = []
  displayGridData: Array<TEVENTCHART> = []
  public hide: boolean = true;
  private filter = "";
  public currentUser: UserInfo;
  public displayedColumns: Array<string> = ['title', 'startDate', 'endDate', 'value', 'mapid', 'action'];

  constructor(private eventSvc: EventsService, 
              public gridService: GridService, 
              private router: Router,
              private authSvc: AuthService) {
    this.sub.add(this.eventSvc.events.pipe(filter(x => x != null)).subscribe(data => {
      this.gridData = [];
      data.forEach(d => {
        this.gridData.push({
          dwIndex: d.dwIndex,
          bID: d.bID,
          szTitle: d.szTitle,
          bGroupID: d.bGroupID,
          bSvrType: d.bSvrType,
          bSvrID: d.bSvrID,
          dStartDate: d.dStartDate,
          dEndDate: d.dEndDate,
          wValue: d.wValue,
          dwStartAlarm: d.dwStartAlarm,
          dwEndAlarm: d.dwEndAlarm,
          szStartMsg: d.szStartMsg,
          szEndMsg: d.szEndMsg,
          szValue: d.szValue,
          wMapID: d.wMapID,
          szMidMsg: d.szMidMsg,
          bPartTime: d.bPartTime
        });
      });
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));

    this.sub.add(this.authSvc.tokenModel$.pipe(filter(x => x != null)).subscribe(u => {
      this.currentUser = u.userInfo;
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.eventSvc.loadData();
  }

  public loadData() {
    this.eventSvc.loadData();
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public editEvent(event: TEVENTCHART) {
    this.eventSvc.onEventSelected.next(event);
    this.router.navigate(['/event-details']);
  }

  public async deleteEvent(dwIndex: number) {
    const result = await this.eventSvc.deleteEvent(dwIndex).toPromise();
    if(result != null && result === true) {
      this.loadData();
    }
  }
}