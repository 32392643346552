import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from 'src/services/login.service';
import { AdminService } from '../swagger';
import * as internal from 'assert';
import { TimeHelper } from '../shared/TimeHelper';
import { environment } from 'src/environments/environment';

export class TokenModel {
  constructor(
    public bearer: string,
    public validTo: Date,
    public userInfo: UserInfo) { }
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  // Todo: 
  // Problem: Token is only x seconds valid.
  // Solution: Intercept https request -> check if result is not_authorized -> if !(tryWithLastCredentials()) { force new login }
  // Solution 2 (which will not work in all cases): Login after app minimize -> maximize

  private CREDENTIALS_STORAGE: string = "oidc.credentials";

  public tokenModel$: BehaviorSubject<TokenModel> = new BehaviorSubject<TokenModel>(null);
  public isAuthorized: boolean;
  public redirectUrl: string; // set by auth.guard.ts
  private currentUser: UserInfo = null;

  constructor(private http: HttpClient, private router: Router) {

    this.tokenModel$.subscribe(tokenModel => {
      this.isAuthorized = tokenModel !== null;
    });
  }

  public async logout() {
    // Clear saved credentials
    localStorage.removeItem('4legacy.admin.oidc.credentials');

    // Inform subscribers
    this.tokenModel$.next(null);

    // route to login page
    this.router.navigate(['/login']);
  }

  public async tryWithLastCredentials(): Promise<boolean> {
    const credentialsJson = localStorage.getItem('4legacy.admin.oidc.credentials');
    const credentials: Credentials = JSON.parse(credentialsJson);

    if (credentials === undefined || credentials === null) {
      return false;
    }

    return await this.tryWithCredentials(credentials);
  }

  public async tryWithCredentials(credentials: Credentials): Promise<boolean> {
    let token: Token = null;

    try {
      token = await this.getToken(credentials.username, credentials.password);
    } catch (error) {
      console.log("tryWithCredentials: Error 2", error);
    }

    if (token == null || token.token == null) {
      return false;
    }

    this.tokenModel$.next(new TokenModel(token.token.value, token.token.validTo, null));

    try {
      this.currentUser = await this.getAdminUserInfo();

      // Credentials are valid, save them for next login.
      localStorage.setItem('4legacy.admin.oidc.credentials', JSON.stringify(credentials));

      this.tokenModel$.next(new TokenModel(token.token.value, token.token.validTo, this.currentUser));
      return true;
    } catch (error) {
      console.log("tryWithCredentials: Error", error);
      return false;
    }
  }

  public async checkTokenIsValid(validTo: Date) {
    // compensate date before validating
    const _validTo: Date = new Date(validTo);
    if(TimeHelper.compensateLocalDST(_validTo).getTime() < new Date().getTime()) {
      console.log('CheckTokenIsValid: ', 'true');
      return true;
    }
    else {
      console.log('CheckTokenIsValid: ', 'false');
      const isLoggedIn: boolean = await this.tryWithLastCredentials();
      if(isLoggedIn) {
        return true;
      }
      else {
        this.logout();
        return false;
      }
    }
  }

  private async getToken(username: string, password: string): Promise<Token> {
    // return await this.http.get<Token>(`http://localhost:59954/api/Admin/Login?user=${username}&password=${password}`).toPromise();
    return await this.http.get<Token>(`${environment.backend}/api/Admin/Login?user=${username}&password=${password}`).toPromise();
  }

  private async getAdminUserInfo(): Promise<UserInfo> {
    return <UserInfo>await this.http
      .get(
        `${environment.backend}/api/Admin/AdminUserInfo`,
        { headers: { "contentType": "application/json" } }
      )
      .toPromise();
  }

  public hasPermissionToRoute(url: string): boolean {
    const permission: number = this.currentUser.bAuthority;

    if(this.currentUser.bAuthority === 1) {
      return true;
    }
    else if(this.currentUser.bAuthority === 2) {
      if(url !== '/manage' && url !== '/server' && url !== '/financials' && url !== '/donate-rewards') {
        return true;
      }
      else {
        return false;
      }
    }
    else if(this.currentUser.bAuthority === 3) {
      if(url === '/online' || url === '/ban') {
        return true;
      }
      else {
        return false;
      }
    }
  }
}

export interface Credentials {
  username: string;
  password: string;
}

export interface Token {
  success?: boolean,
  token?: {
    value: string;
    validTo: Date;
  }
}

export interface UserInfo {
  szID?: string;
  szPasswd?: string;
  bOPAuthority?: number;
  bAuthority?: number;
  szName?: string;
  szPhoneNum?: string;
  szOpratorCharID?: string;
  dCreateDate?: string;
}