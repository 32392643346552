import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Coupon, CouponDto, CouponItem, CouponService } from 'src/services/coupon.service';

@Component({
  selector: 'app-coupon-creat-edit',
  templateUrl: './coupon-creat-edit.component.html',
  styleUrls: ['./coupon-creat-edit.component.scss']
})
export class CouponCreatEditComponent implements OnInit, OnDestroy {

  public couponType = "Cash Coupon";
  public couponTypes = ['Cash Coupon', 'Item Coupon'];

  //cash option
  public cashAmount: number;
  public usageLimit: number;
  public isUnlimited: boolean = false;

  public isActive: boolean;
  public uCouponId: string;

  //item option
  public items: CouponItem[] = [{itemId: 55, count: 5}];

  private sub: Subscription = new Subscription();
  public isCreateMode: boolean;

  constructor(private couponSvc: CouponService, private router: Router) {
    this.sub.add(this.couponSvc.onEditCoupon.subscribe(coupon => {
      this.isCreateMode = coupon == null;
      if(!this.isCreateMode) {
        this.couponType = coupon.isCash ? 'Cash Coupon' : 'Item Coupon';
        this.cashAmount = coupon.wAmount;
        this.usageLimit = coupon.usageLimit;
        this.isUnlimited = coupon.usageLimit === 9999;
        this.items = coupon.items;
        this.isActive = coupon.isActive;
        this.uCouponId = coupon.uCouponID;
      }
    }))
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() { }

  public async createCoupon() {
    if(this.isCreateMode) {
      let newCoupon: CouponDto;
      if(this.couponType === 'Cash Coupon') {
        newCoupon = {
          wAmount: this.cashAmount,
          usageLimit: this.isUnlimited ? 9999 : this.usageLimit,
          isCash: true,
          items: null
        }
      }
      else {
        newCoupon = {
          wAmount: 0,
          usageLimit: this.isUnlimited ? 9999 : this.usageLimit,
          isCash: false,
          items: JSON.stringify(this.items)
        }
      }
  
      try {
        const coupon: CouponDto = await this.couponSvc.createCoupon(newCoupon).toPromise();
        if(coupon) {
          this.router.navigate(['/coupons']);
        }
      }
      catch(e) {
        console.error(e);
      }
    }
    else {
      const updatedCoupon: CouponDto = {
        uCouponID: this.uCouponId,
        wAmount: this.cashAmount,
        usageLimit: this.isUnlimited ? 9999 : this.usageLimit,
        items: this.couponType === 'Cash Coupon' ? null : JSON.stringify(this.items),
        isActive: this.isActive
      }

      try{
        const result = await this.couponSvc.updateCoupon(updatedCoupon).toPromise();
        if(result) {
          this.router.navigate(['/coupons']);
        }
      }
      catch(e) {
        console.error(e);
      }
    }
  }

  public addItem() {
    this.items.push({itemId: 55, count: 5});
  }

  public goBack() {
    this.router.navigate(['/coupons'])
  }

  public removeItem(itemId: number) {
    this.items = this.items.filter(i => i.itemId !== itemId);
  }
}