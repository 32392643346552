import * as datefns from 'date-fns';

export class TimeHelper {
    public static revertLocalDST(date: Date): Date {
        if (!date) return;
        if (this.isDstObserved(date)) {
            return datefns.addMinutes(date, -1 * this.stdTimezoneOffset());
        }
        return date;
    }

    public static compensateLocalDST(date: Date): Date {
        if (!date) return;
        if (this.isDstObserved(date)) {
            return datefns.addMinutes(date, this.stdTimezoneOffset());
        }
        return date;
    }

    private static isDstObserved(date: Date) {
        return date.getTimezoneOffset() < this.stdTimezoneOffset();
    }

    private static stdTimezoneOffset() {
        const jan = new Date(new Date().getFullYear(), 0, 1);
        const jul = new Date(new Date().getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }
}