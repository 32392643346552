import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public events: BehaviorSubject<TEVENTCHART[]> = new BehaviorSubject<TEVENTCHART[]>([]);
  public onEventSelected: BehaviorSubject<TEVENTCHART> = new BehaviorSubject<TEVENTCHART>(null);

  constructor(private http: HttpClient) { }

  public async loadData() {
    this.events.next(
      await this.getEvents().toPromise()
    )
  }

  public getEvents() {
    return this.http.get<TEVENTCHART[]>(`${environment.backend}/api/Admin/Events`, { headers: {"contentType": "application/json" }});
  }

  public createEvent(event: TEVENTCHART) {
    return this.http.post<boolean>(`${environment.backend}/api/Admin/CreateEvent`, event, { headers: {"contentType": "application/json" }});
  }

  public updateEvent(event: TEVENTCHART) {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/UpdateEvent`, event, { headers: {"contentType": "application/json" }});
  }

  public deleteEvent(index: number) {
    return this.http.delete<boolean>(`${environment.backend}/api/Admin/DeleteEvent?index=${index}`, { headers: {"contentType": "application/json" }});
  }
}

export interface TEVENTCHART {
  dwIndex?: number;
  bID: number;
  szTitle: string;
  bGroupID: number;
  bSvrType: number;
  bSvrID: number;
  dStartDate: string;
  dEndDate: string;
  wValue: number;
  dwStartAlarm: number;
  dwEndAlarm: number;
  szStartMsg: string;
  szEndMsg: string;
  szValue: string;
  wMapID: number;
  szMidMsg: string;
  bPartTime: number;
}