import { GridService } from './../../../services/grid.service';
import { Component, OnInit, Inject } from '@angular/core';
import { SwaggerService } from 'src/services/swagger.service';
import { TCHARTABLE } from 'src/app/swagger';
import { MatDialog, PageEvent } from '@angular/material';
import { BanplayerComponent } from '../dialogs/banplayer/banplayer.component';
import { LoginService } from 'src/services/login.service';
import { Subscription } from 'rxjs';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { filter } from 'rxjs/internal/operators/filter';
import { BanPlayerService } from 'src/services/ban-player.service';

@Component({
  selector: 'app-ban-player',
  templateUrl: './ban-player.component.html',
  styleUrls: ['./ban-player.component.scss']
})
export class BanPlayerComponent implements OnInit {

  gridData: Array<TCHARTABLE> = []
  displayGridData: Array<TCHARTABLE> = []
  private sub: Subscription = new Subscription;

  public displayedColumns: Array<string> = ['dwUserID', 'dwCharID', 'szNAME', 'bLevel', 'bCountry', 'actions'];

  private filter = "";
  public currentUser: UserInfo = null;

  constructor(public dialog: MatDialog, 
              public gridService: GridService,
              private authSvc: AuthService,
              private banSvc: BanPlayerService) { }

  ngOnInit() {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
      this.gridService.reset();
      this.currentUser = token.userInfo;
      this.loadData();
    }));
  }

  async loadData() {
    this.gridData = await this.banSvc.allCharacters().toPromise();
    this.gridService.pagerHelper.length = this.gridData.length;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  async ban(char: TCHARTABLE) {
    const dialogRef = this.dialog.open(BanplayerComponent, {
      width: '400px',
      data: char
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result)
    });
  }

  public getClassName(bClass: number): string {
    switch(bClass) {
      case 0: return 'Krieger';
      case 1: return 'Schattenläufer';
      case 2: return 'Bogenschütze';
      case 3: return 'Magier';
      case 4: return 'Priester';
      case 5: return 'Beschwörer';
    }
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }
}