import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { DonateHistory, FinancialsService } from 'src/services/financials.service';
import { GridService } from 'src/services/grid.service';
import { getWeek } from 'date-fns';

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.scss']
})
export class FinancialsComponent implements OnInit, OnDestroy {

  private sub: Subscription = new Subscription();

  gridData: Array<DonateHistory> = []
  displayGridData: Array<DonateHistory> = []
  private filter = "";
  public hide: boolean = true;
  public displayedColumns: Array<string> = ['User', 'Amount', 'Method', 'Order','Date', 'Completed', 'Status'];

  public totalIncome: number = 0;
  public monthlyIncome: number = 0;
  public weeklyIncome: number = 0;

  constructor(public gridService: GridService, private finacialSvc: FinancialsService) {
    this.sub.add(this.finacialSvc.payments.pipe(filter(x => x != null)).subscribe(data => {
      this.gridData = [];
      this.totalIncome = 0;
      this.monthlyIncome = 0;
      this.weeklyIncome = 0;
      data.forEach(d => {
        this.addIncome(d);
        this.gridData.push({
          id: d.id,
          amount: d.amount,
          buyer_email: d.buyer_email,
          date: d.date,
          isCompleted: d.isCompleted,
          orderId: d.orderId,
          paymentMethod: d.paymentMethod,
          userId: d.userId,
          paymentStatus: d.paymentStatus
        })
      });
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.finacialSvc.loadData();
  }

  public loadData() {
    this.finacialSvc.loadData();
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  private addIncome(d: DonateHistory) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentWeek = getWeek(new Date());
    const weekOfGivenDate = getWeek(new Date(d.date));
    const givenDate = new Date(d.date);

    this.totalIncome += d.amount;

    if (givenDate.getFullYear() === currentYear && givenDate.getMonth() === currentMonth) {
      this.monthlyIncome += d.amount;
    }
    if (givenDate.getFullYear() === currentYear && weekOfGivenDate === currentWeek) {
      this.weeklyIncome += d.amount;
    }
  }
}
