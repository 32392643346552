import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ServerStatusService {

  constructor(private http: HttpClient) { }

  public getServerStatus(): Observable<number> {
    return this.http.get<number>('${environment.backend}/api/Admin/ServerStatus', { headers: {"contentType": "application/json" }});
  }

  public updateServerStatus(status: number): Observable<number> {
    return this.http.put<number>(`${environment.backend}/api/Admin/UpdateServerStatus?status=${status}`, { headers: {"contentType": "application/json" }});
  }
}