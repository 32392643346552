import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {catchError, filter} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs/index';
import {throwError} from 'rxjs';
import { TokenModel, AuthService } from 'src/app/core/auth.service';
import { LoadingbarService } from './loadingbar.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpintercepterService implements HttpInterceptor, OnDestroy {

  private sub: Subscription = new Subscription;
  private currentUserToken: TokenModel;

  constructor(private authSvc: AuthService) {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(x => x !== null)).subscribe(token => {
        this.currentUserToken = token;
    }));
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!request.url.includes(`${environment.backend}/api/Admin/Login`)) {
      // this.authSvc.checkTokenIsValid(this.currentUserToken.validTo);
      const authReq = this.addToken(request, this.currentUserToken.bearer);
      return next.handle(authReq).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            switch ((<HttpErrorResponse> error).status) {
              case 401:
                return this.handleUnauthorized(request, next);
              default:
                return this.handle4xxError(error);
            }
          } else {
            return throwError(error);
          }
        }),
      );
    } else {
      return next.handle(request);
    }
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return token ? req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    }) : req;
  }

  private handle4xxError(error): Observable<any> {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.authSvc.logout();
      return throwError(error);
    }

    return throwError(error);
  }

  private handleUnauthorized(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const credentialsJson = localStorage.getItem('4legacy.admin.oidc.credentials');
    if(credentialsJson) {
      this.authSvc.tryWithLastCredentials().then(
        success => this.intercept(request, next),
        error => throwError("Request rejected: Unauthorized!")
      )
    }
    else {
      this.authSvc.logout();
      return throwError("Request rejected: Unauthorized!");
    }
  }
}