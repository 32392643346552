import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService, TEVENTCHART } from 'src/services/events.service';

export interface EventType {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit, OnDestroy {

  private sub: Subscription = new Subscription();
  public isCreateMode: boolean = false;
  public eventTypes: EventType[] = [
    {value: 1, viewValue: 'Experience'},
    {value: 1, viewValue: 'Cash Item Sale'},
    {value: 2, viewValue: 'Item Drop'},
    {value: 3, viewValue: 'Magic Item Drop'},
    {value: 4, viewValue: 'Refine'},
    {value: 5, viewValue: 'Refine Transfer'},
    {value: 6, viewValue: 'Imte Upgrade'},
    {value: 7, viewValue: 'Magic Upgrade'},
    {value: 8, viewValue: 'Rare Magic Upgrade'},
    {value: 9, viewValue: 'Gamble Option'},
    {value: 10, viewValue: 'Money Drop'},
    {value: 11, viewValue: 'Monster Spawn'},
    {value: 12, viewValue: 'Monster Regen'},
    {value: 13, viewValue: 'Item Lottery'},
    {value: 14, viewValue: 'Gift Time'},
    {value: 15, viewValue: 'PVP (Honor)'}
  ];

  // ngModels
  public category: number;
  public dwIndex: number;
  public description: string;
  public value: number = 0;
  public mapID: number = 0;
  public startDate: string;
  public endDate: string;
  public startAlarm: number = 0;
  public endAlarm: number = 0;
  public startMessage: string;
  public endMessage: string;

  // optional private variables
  private selectedEvent: EventType = { value: 1137, viewValue: 'Event' };

  constructor(private eventSvc: EventsService, private _snackBar: MatSnackBar) {
    this.sub.add(this.eventSvc.onEventSelected.subscribe(x => {
      if(x == null) {
        this.isCreateMode = true;
      }
      else {
        this.isCreateMode = false;
        this.dwIndex = x.dwIndex;
        this.category = x.bID;
        this.description = x.szTitle;
        this.value = x.wValue;
        this.mapID = x.wMapID;
        this.startDate = x.dStartDate;
        this.endDate = x.dEndDate;
        this.startAlarm = x.dwStartAlarm;
        this.endAlarm = x.dwEndAlarm;
        this.startMessage = x.szStartMsg;
        this.endMessage = x.szEndMsg;
      }
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() { }

  public updateEventMessage() {
    this.startMessage = `${this.value}% ${this.selectedEvent.viewValue} Event has started!`;
    this.endMessage = `${this.value}% ${this.selectedEvent.viewValue} Event has finished!`;
  }

  public onEventChanged(event) {
    this.selectedEvent = this.eventTypes.find(e => e.value === event.value);
    this.updateEventMessage();
  }

  public async createOrUpdateEvent(): Promise<boolean> {
    console.log(this.eventSvc.events.value)
    if(this.isCreateMode && this.eventSvc.events.value.find(e => e.bID === this.selectedEvent.value)) {
      console.log('Event already exist!')
      this._snackBar.open(`This event already exists and cant be created twice!`, '', {duration: 3000, verticalPosition: 'top'});
      return false;
    }
    if(this.value === 0) {
      this._snackBar.open(`Value has to be higher then 0!`, '', {duration: 3000, verticalPosition: 'top'});
      return false;
    }
    if(this.startDate == null || this.endDate == null) {
      this._snackBar.open(`Start- and End-Date cant be empty!`, '', {duration: 3000, verticalPosition: 'top'});
      return false;
    }

    const event: TEVENTCHART = {
      dwIndex: this.dwIndex,
      bID: 1,
      szTitle: this.selectedEvent.viewValue,
      bGroupID: 1,
      bSvrType: 3,
      bSvrID: 0,
      dStartDate: this.startDate,
      dEndDate: this.endDate,
      wValue: this.value,
      dwStartAlarm: this.startAlarm,
      dwEndAlarm: this.endAlarm,
      szStartMsg: this.startMessage,
      szEndMsg: this.endMessage,
      wMapID: this.mapID,
      szMidMsg: "",
      bPartTime: 0,
      szValue: ""
    }

    const result = this.isCreateMode ? await this.eventSvc.createEvent(event).toPromise() : await this.eventSvc.updateEvent(event).toPromise();

    if(result != null && result === true) {
      this._snackBar.open(`Successfully created/updated ${this.selectedEvent.viewValue} event!`, '', {duration: 3000, verticalPosition: 'top'});
    }
    else {
      this._snackBar.open(`Error creating/updating event!`, '', {duration: 3000, verticalPosition: 'top'});
    }

    return result;
  }
}