import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingbarService {

  constructor() { }
  
  public start() {
    var loadingAnimation: HTMLElement = document.getElementById('loadingAnimation')
    loadingAnimation.classList.add('loading');
  }

  public stop() {
    var loadingAnimation: HTMLElement = document.getElementById('loadingAnimation')
    loadingAnimation.classList.remove('loading');
  }
}
