import { Component, Inject } from '@angular/core';
import { BanPlayerDto, TCHARTABLE } from 'src/app/swagger';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { SwaggerService } from 'src/services/swagger.service';
import { AuthService, TokenModel } from 'src/app/core/auth.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { BanPlayerService } from 'src/services/ban-player.service';

@Component({
  selector: 'app-banplayer',
  templateUrl: './banplayer.component.html',
  styleUrls: ['./banplayer.component.scss']
})
export class BanplayerComponent {

  perm: boolean = true;
  dura: boolean = false;
  hwidban: boolean = false;
  duration: string;
  comment: string = "";
  private sub: Subscription = new Subscription();
  private currentAdmin: TokenModel;

  constructor(private banSvc: BanPlayerService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<BanplayerComponent>,
    @Inject(MAT_DIALOG_DATA) public element: TCHARTABLE,
    private swagger: SwaggerService, private authSvc: AuthService) {
      this.sub.add(this.authSvc.tokenModel$.pipe(filter(x => x != null)).subscribe(u => {
        this.currentAdmin = u;
      }));
    }

  permChange() {
    this.dura = false;
  }

  duraChange() {
    this.perm = false;
    this.hwidban = false;
  }

  onNoClick(): void {
    console.log(this.element)
    console.log("close")
    this.dialogRef.close();
  }

  async onSaveClick() {
    const newData: BanPlayerDto = {
      dwUserID: this.element.dwUserID,
      dwCharID: this.element.dwCharID,
      szCharName: this.element.szNAME,
      bBlockType: 0,
      startTime: new Date(),
      dwDuration: this.perm ? 9999999 : +this.duration,
      bBlockReason: 0,
      szComment: this.comment,
      szGMID: this.currentAdmin.userInfo.szName,
      isHwidBan: this.hwidban
    };

    // await this.swagger.admin.apiAdminBanPlayerPost(newData).toPromise();
    const result = await this.banSvc.banPlayer(newData).toPromise();
    if(result) {
      this._snackBar.open(`${this.element.szNAME} was successfully banned!`, '', {duration: 3000, verticalPosition: 'top'});
    }
    else {
      this._snackBar.open(`Error banning ${this.element.szNAME}. Please report to Soulmate!`, '', {duration: 3000, verticalPosition: 'top'});
    }
    this.dialogRef.close();
  }

}
