import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Quill from 'quill';
import { filter } from 'rxjs/operators';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { NewsfeedEntry, NewsfeedService } from 'src/services/newsfeed.service';

@Component({
  selector: 'app-create-newsfeed-dialog',
  templateUrl: './create-newsfeed-dialog.component.html',
  styleUrls: ['./create-newsfeed-dialog.component.scss']
})
export class CreateNewsfeedDialogComponent implements OnInit, OnDestroy {

  public myToolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean'],
    ['image'] //add image here
  ];

  public selectedType: number;
  public selectedId: string;
  public title: string = "";
  public date: string = "";
  public status: number;

  private sub: Subscription = new Subscription();
  public newsfeedTypes: NewsfeedType[] = [
    {
      value: 0,
      viewValue: 'Announcement'
    }, 
    {
      value: 1,
      viewValue: 'Maintenance'
    }, 
    {
      value: 2,
      viewValue: 'Update'
    },
    {
      value: 3,
      viewValue: 'Event'
    }];

    private _toolbar: any[] = [
      ['bold', 'italic', 'underline', 'strike'],       
      ['blockquote', 'code-block'],
  
      [{ 'color': [] }, { 'background': [] }],         
      [{ 'font': [] }],
      [{ 'align': [] }],
  
      ['clean'],                                        
      ['image'] //add image here
  ];

  private quill: Quill = "";

  public editorContent: any;
  public currentUser: UserInfo = null;
  private isCreateMode: boolean = false;

  constructor(private newsfeedSvc: NewsfeedService, 
              private router: Router, 
              private route: ActivatedRoute,
              private authSvc: AuthService) {
    this.sub.add(this.newsfeedSvc.onEditNewsfeedEntry.subscribe(entry => {
      this.title = entry.title;
      this.selectedId = entry.id;
      this.date = entry.date;
      this.editorContent = entry.message;
      this.selectedType = entry.status;
    }));

    this.sub.add(this.authSvc.tokenModel$.pipe(filter(x => x != null)).subscribe(token => {
      this.currentUser = token.userInfo;
    }));

    this.sub.add(this.route.params.subscribe(async params => {
      if (params['id'] != null) {
        this.isCreateMode = false;
      }
      else {
        this.isCreateMode = true;
      }
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() { }

  public async create() {
    if (this.isCreateMode) {
      try {
        const entry: NewsfeedEntry = {
          title: this.title,
          date: new Date(this.date).toISOString(),
          message: this.editorContent.replace(/^\s+|\s+$/g, ''), 
          status: this.selectedType,
          author: this.currentUser.szName
        }
        await this.newsfeedSvc.createNewsfeedEntry(entry).toPromise();
      }
      catch (e) {
        console.error(e);
      }
    }
    else {
      try {
        const entry: NewsfeedEntry = {
          id: this.selectedId,
          title: this.title,
          date: new Date(this.date).toISOString(),
          message: this.editorContent.replace(/^\s+|\s+$/g, ''),
          status: this.selectedType,
          author: this.currentUser.szName
        }
        await this.newsfeedSvc.updateNewsfeedEntry(entry).toPromise();
      }
      catch (e) {
        console.error(e);
      }
    }
    this.router.navigate(['/newsfeed']);
  }
}

export interface NewsfeedType {
  value: number;
  viewValue: string;
}