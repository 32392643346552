import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FinancialsService {

  public payments: BehaviorSubject<DonateHistory[]> = new BehaviorSubject<DonateHistory[]>([]);
  public onEditPayment: BehaviorSubject<DonateHistory> = new BehaviorSubject<DonateHistory>(null);
  public calculatedIncome: boolean = false;
  
  constructor(private http: HttpClient) { }

  public async loadData() {
    this.payments.next(
      await this.getPaymentsByMethod("paypal").toPromise()
    )
    this.calculatedIncome = true;
  }

  public getPaymentsByMethod(method: string): Observable<DonateHistory[]> {
    return this.http.get<DonateHistory[]>(`${environment.backend}/api/Admin/DonateHistoryByMethod?method=${method}`, { headers: {"contentType": "application/json" }});
  }

  public getDonateRewards(): Observable<DonateReward[]> {
    return this.http.get<DonateReward[]>(`${environment.backend}/api/Admin/DonateRewards`, { headers: {"contentType": "application/json" }});
  }

  public updateDonateReward(reward: DonateReward): Observable<boolean> {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/UpdateDonateReward`, reward, { headers: {"contentType": "application/json" }});
  }
}

export interface DonateHistory {
  id: string;
  paymentMethod: string;
  date: string;
  orderId: string;
  userId: number;
  isCompleted: boolean;
  amount: number;
  buyer_email: string;
  paymentStatus: string;
}

export interface DonateReward {
  id: string;
  amount: number;
  ms: number;
  bonus: number;
}