import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DonateReward, FinancialsService } from 'src/services/financials.service';
import { GridService } from 'src/services/grid.service';

@Component({
  selector: 'app-donate-rewards',
  templateUrl: './donate-rewards.component.html',
  styleUrls: ['./donate-rewards.component.scss']
})
export class DonateRewardsComponent implements OnInit, OnDestroy {

  gridData: Array<DonateReward> = []
  displayGridData: Array<DonateReward> = []
  private filter = "";
  public displayedColumns: Array<string> = ['amount', 'ms', 'bonus', 'action'];

  private sub: Subscription = new Subscription();

  constructor(private financialSvc: FinancialsService,
              public gridService: GridService, 
              private _snackBar: MatSnackBar) { }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.loadData();
  }

  public async loadData() {
    try {
      this.gridData = await this.financialSvc.getDonateRewards().toPromise();
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }
    catch(e) {
      console.error(e);
    }
  }

  public async update(reward: DonateReward) {
    try {
      const result = await this.financialSvc.updateDonateReward(reward).toPromise();
      if(result) {
        this._snackBar.open(`Reward was successfuly updated!`, '', {duration: 3000, verticalPosition: 'top'});
      }
    }
    catch(e) {
      this._snackBar.open(`Error updating reward!`, '', {duration: 3000, verticalPosition: 'top'});
    }
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }
}