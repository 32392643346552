import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserInfo } from 'src/app/core/auth.service';
import { ManageService } from 'src/services/manage.service';
import { SupportService, SupportTicket, TicketCategory } from 'src/services/support.service';

@Component({
  selector: 'app-support-ticket-details-edit',
  templateUrl: './support-ticket-details-edit.component.html',
  styleUrls: ['./support-ticket-details-edit.component.scss']
})
export class SupportTicketDetailsEditComponent implements OnInit {

  public categories: TicketCategory[] = [];
  public adminUsers: UserInfo[] = [];

  public category: string;
  public adminAssigned: string;

  constructor(private supportSvc: SupportService,
              private manageSvc: ManageService,
              public dialogRef: MatDialogRef<SupportTicketDetailsEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SupportTicket) {
      this.getAdmins();
      this.categories = this.supportSvc.ticketCategories;
      this.category = data.category;
      this.adminAssigned = data.adminAssigned;
    }

  ngOnInit() { }

  private async getAdmins() {
    this.adminUsers = await this.manageSvc.getAdminUsers().toPromise();
  }

  public applyChanges() {
    this.dialogRef.close({category: this.category, adminAssigned: this.adminAssigned});
  }
}
