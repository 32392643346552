import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { GridService } from '../../../services/grid.service';
import { LoginService } from '../../../services/login.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog, MatTableDataSource, PageEvent } from '@angular/material';
import { Version, VersionService } from 'src/services/version.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit, OnDestroy {

  public gridData: Array<Version> = [];
  public displayGridData: Array<Version> = [];
  public displayedColumns: Array<string> = ['version', 'client', 'createdAt', 'action'];
  private filter: string = "";
  private sub: Subscription = new Subscription();

  constructor(public dialog: MatDialog,
    public gridService: GridService,
    private versionSvc: VersionService,
    private authSvc: AuthService) {
    this.sub.add(this.versionSvc.versions.subscribe(data => {
      this.gridData = [];
      data.forEach(d => this.gridData.push({
        id: d.id,
        major: d.major,
        minor: d.minor,
        patch: d.patch,
        createdAt: d.createdAt,
        clientType: d.clientType
      }));
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
      this.gridService.reset();
      this.versionSvc.loadData();
    }));
  }

  public async remove(id: string) {
    try {
      this.versionSvc.removeVersion(id).toPromise();

      const index = this.gridData.findIndex(x => x.id === id);
      if (index > -1) {
        this.gridData.splice(index, 1);
      }
      this.displayGridData = this.gridData;
    }
    catch (e) {
      console.error(e);
    }
  }

  public refresh() {
    this.versionSvc.loadData();
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  // public async addClientVersion() {
  //   try {
  //     const result: boolean = await this.versionSvc.addClientVersion().toPromise();
  //     if (result) {
  //       this.gridData.push({
  //         id: "",
  //         version: this.gridData[this.gridData.length - 1].version + 1,
  //         date: new Date().toISOString(),
  //         downloadLink: ""
  //       });
  //       this.displayGridData = this.gridData;
  //     }
  //   }
  //   catch (e) {
  //     console.error(e);
  //   }
  // }
}