
import { LoginService } from './../../../services/login.service';
import { SwaggerService } from './../../../services/swagger.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TUSERPROTECTED } from 'src/app/swagger';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, PageEvent } from '@angular/material';
import { GridService } from 'src/services/grid.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { filter } from 'rxjs/internal/operators/filter';
import { BanPlayerService } from 'src/services/ban-player.service';

@Component({
  selector: 'app-unban-player',
  templateUrl: './unban-player.component.html',
  styleUrls: ['./unban-player.component.scss']
})
export class UnbanPlayerComponent implements OnInit {

  gridData: Array<TUSERPROTECTED> = []
  displayGridData: Array<TUSERPROTECTED> = []

  public displayedColumns: Array<string> = ['dwUserID', 'dwCharID', 'szCharName', 'dwDuration', 'szComment', 'remainingDays', 'actions'];

  private filter: string = "";
  private sub: Subscription = new Subscription;

  constructor(public dialog: MatDialog, 
              public gridService: GridService,
              private authSvc: AuthService,
              private banSvc: BanPlayerService) { }

  ngOnInit() {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token !== null)).subscribe(token => {
      this.gridService.reset();
      this.loadData();
    }));
  }

  async loadData() {
    const data = await this.banSvc.bannedPlayerList().toPromise();
    data.forEach(d => {
      // check when ban was made, 
      const banOverDate = new Date(d.startTime);
      banOverDate.setDate(banOverDate.getDate() + d.dwDuration);
      d.remainingDays = banOverDate;
      d.isOver = this.isInThePast(banOverDate);
    });
    this.gridData = data;
    this.gridService.pagerHelper.length = this.gridData.length;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  private isInThePast(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    return date < today;
  }

  public async unban(element: TUSERPROTECTED) {
    // await this.swagger.admin.apiAdminUnbanPlayerDelete(element.dwUserID).toPromise();
    console.log(element.dwUserID);
    const result = await this.banSvc.unbanPlayer(element.dwUserID).toPromise();
    if(result) {
      this.loadData();
    }
  }

  public edit(element) {
    const dialogRef = this.dialog.open(Editdialog, {
      width: '400px',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.loadData();
    });
  }

  public getClassName(bClass: number): string {
    switch(bClass) {
      case 0: return 'Krieger';
      case 1: return 'Schattenläufer';
      case 2: return 'Bogenschütze';
      case 3: return 'Magier';
      case 4: return 'Priester';
      case 5: return 'Beschwörer';
    }
  }
  
  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }
}

@Component({
  selector: 'editdialog',
  templateUrl: 'editdialog.html',
})
export class Editdialog {

  duration: string;

  constructor(private banSvc: BanPlayerService,
    public dialogRef: MatDialogRef<Editdialog>,
    @Inject(MAT_DIALOG_DATA) public element: TUSERPROTECTED,
    private swagger: SwaggerService) { }


  onNoClick(): void {
    console.log("close")
    this.dialogRef.close();
  }

  async onSaveClick() {
    console.log(this.duration);
    await this.banSvc.banPlayerDuration(this.element.dwUserID, parseInt(this.duration)).toPromise();
    // await this.swagger.admin.apiAdminBanPlayerDurationPut(this.element.dwUserID, parseInt(this.duration)).toPromise();
    this.dialogRef.close();
  }
}
