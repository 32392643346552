import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BanPlayerDto } from 'src/app/swagger/model/banPlayerDto';
import { TCHARTABLE } from 'src/app/swagger/model/tCHARTABLE';
import { TUSERPROTECTED } from 'src/app/swagger/model/tUSERPROTECTED';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BanPlayerService {

  constructor(private http: HttpClient) { }

  public unbanPlayer(dwUserID: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.backend}/api/Admin/UnbanPlayer?dwUserID=${dwUserID}`, { headers: {"contentType": "application/json" }});
  }

  public bannedPlayerList(): Observable<TUSERPROTECTED[]> {
    return this.http.get<TUSERPROTECTED[]>(`${environment.backend}/api/Admin/BannedPlayerList`, { headers: {"contentType": "application/json" }});
  }

  public allCharacters(): Observable<TCHARTABLE[]> {
    return this.http.get<TCHARTABLE[]>(`${environment.backend}/api/Admin/Characters`, { headers: {"contentType": "application/json" }});
  }

  public banPlayer(player: BanPlayerDto): Observable<boolean> {
    return this.http.post<boolean>(`${environment.backend}/api/Admin/BanPlayer`, player, { headers: {"contentType": "application/json" }});
  }

  public banPlayerDuration(dwUserID: number, dwDuration: number): Observable<boolean> {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/BanPlayerDuration?dwUserID=${dwUserID}&dwDuration=${dwDuration}`, { headers: {"contentType": "application/json" }});
  }
}