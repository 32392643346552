import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public versions: BehaviorSubject<Version[]> = new BehaviorSubject<Version[]>([]);

  constructor(private http: HttpClient) { }

  public async loadData() {
    this.versions.next(
      await this.getClientVersions().toPromise()
    );
  }

  private getClientVersions(): Observable<Version[]> {
    return this.http.get<Version[]>('${environment.backend}/api/Admin/GetClientVersions', { headers: {"contentType": "application/json" }});
  }

  public addClientVersion(): Observable<boolean> {
    return this.http.post<boolean>('${environment.backend}/api/Admin/AddClientVersion', { headers: {"contentType": "application/json" }});
  }

  public removeVersion(id: string) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.backend}/api/Admin/RemoveClientVersion?version=${id}`, { headers: {"contentType": "application/json" }});
  }
}

export interface Version {
  id?: string;
  major?: number;
  minor?: number;
  patch?: number;
  createdAt?: string;
  clientType?: number;
}