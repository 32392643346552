import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public loggedIn: boolean = false;

  constructor(private router: Router) {}


  checkIfLogin() {
    if(!this.loggedIn) {
      //this.router.navigate(['login']);
    }
  }
}
