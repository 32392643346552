import { Injectable } from '@angular/core';

export interface PagerHelper {
  pageSizer: Array<number>;
  pageIndex: number;
  pageSize: number;
  length: number;
}

@Injectable({
  providedIn: 'root'
})
export class GridService {

  public pagerHelper: PagerHelper = {
    pageSizer: [10, 20, 50],
    pageIndex: 0,
    pageSize: 10,
    length: 0
  }

  constructor() { }

  reset() {
    this.pagerHelper = {
      pageSizer: [20, 50, 100],
      pageIndex: 0,
      pageSize: 20,
      length: 0
    }
  }

  sliceData(data: Array<any>, filter: string) {
    if (filter != "") {
      const dataFiltered = data.filter(gD =>
        gD.dwUserID.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        gD.dwCharID.toString().toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
        gD.szNAME.toLowerCase().indexOf(filter.toLowerCase()) > -1
      )
      this.pagerHelper.length = dataFiltered.length;
      return dataFiltered.slice(
        this.pagerHelper.pageIndex * this.pagerHelper.pageSize,
        this.pagerHelper.pageIndex * this.pagerHelper.pageSize + this.pagerHelper.pageSize
      )
    }
    return data.slice(
      this.pagerHelper.pageIndex * this.pagerHelper.pageSize,
      this.pagerHelper.pageIndex * this.pagerHelper.pageSize + this.pagerHelper.pageSize
    )
  }
}
