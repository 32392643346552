import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {

  public newsfeedEntries: BehaviorSubject<NewsfeedEntry[]> = new BehaviorSubject<NewsfeedEntry[]>([]);
  public onEditNewsfeedEntry: BehaviorSubject<NewsfeedEntry> = new BehaviorSubject<NewsfeedEntry>(null);

  constructor(private http: HttpClient) { }

  public async loadData() {
    this.newsfeedEntries.next(
      await this.getNewsfeedEntries().toPromise()
    )
  }

  private getNewsfeedEntries(): Observable<NewsfeedEntry[]> {
    return this.http.get<NewsfeedEntry[]>(`${environment.backend}/api/Newsfeed/AllNewsfeedEntries`, { headers: {"contentType": "application/json" }});
  }

  public createNewsfeedEntry(entry: NewsfeedEntry): Observable<boolean> {
    return this.http.post<boolean>(`${environment.backend}/api/Admin/CreateNewsfeedEntry`, entry, { headers: {"contentType": "application/json" }});
  }

  public updateNewsfeedEntry(entry: NewsfeedEntry): Observable<boolean> {
    return this.http.put<boolean>(`${environment.backend}/api/Admin/UpdateNewsfeedEntry`, entry, { headers: {"contentType": "application/json" }});
  }

  public removeNewsfeedEntry(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.backend}/api/Admin/DeleteNewsfeedEntry?id=${id}`, { headers: {"contentType": "application/json" }});
  }
}

export interface NewsfeedEntry {
  id?: string;
  title?: string;
  message?: string | any;
  date?: string;
  status?: number;
  author?: string;
}