import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { Subscription } from 'rxjs/internal/Subscription';
import { Coupon, CouponDto, CouponItem, CouponService } from 'src/services/coupon.service';
import { GridService } from 'src/services/grid.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CouponsComponent implements OnInit {

  public gridData: Array<Coupon> = [];
  public displayGridData: Array<Coupon> = [];
  public displayedColumns: Array<string> = ['Coupon', 'CreatedAt', 'RedeemedCount', 'Type', 'Status', 'action'];
  private filter: string = "";
  private sub: Subscription = new Subscription();
  public expandedElement: Coupon | null;

  constructor(private couponSvc: CouponService, public gridService: GridService, private router: Router) {
    this.sub.add(this.couponSvc.coupons.pipe(filter(x => x != null)).subscribe(data => {
      this.gridData = [];
      data.forEach(d => this.gridData.push({
        wID: d.wID,
        uCouponID: d.uCouponID,
        creationTime: d.creationTime,
        isActive: d.isActive,
        isCash: d.isCash,
        isRedeemed: d.isRedeemed,
        items: JSON.parse(d.items),
        redeemedCount: d.redeemedCount,
        usageLimit: d.usageLimit,
        wAmount: d.wAmount,
        wUserID: d.wUserID
      }));
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }));
  }

  ngOnInit() {
    this.couponSvc.loadData();
  }

  public refresh() {
    this.couponSvc.loadData();
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }

  public async toggleCouponState(couponId: string) {
    const coupon = this.gridData.find(x => x.uCouponID === couponId);
    const _updateCoupon: CouponDto = {
      uCouponID: coupon.uCouponID,
      wAmount: coupon.wAmount,
      usageLimit: coupon.usageLimit,
      items: JSON.stringify(coupon.items),
      isActive: !coupon.isActive
    }
    try {
      const result = await this.couponSvc.updateCoupon(_updateCoupon).toPromise();
      if(result) {
        this.couponSvc.loadData();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public editCoupon(element) {
    console.log(element);
    this.couponSvc.onEditCoupon.next(element);
    this.router.navigate(['/coupon-create-edit']);
  }

  public createCoupon() {
    this.couponSvc.onEditCoupon.next(null);
    this.router.navigate(['/coupon-create-edit']);
  }

  public async deleteCoupon(id: string) {
    try {
      const result = await this.couponSvc.deleteCoupon(id).toPromise();
      if(result){
        this.couponSvc.loadData();
      }
    }
    catch(e) {
      console.error(e);
    }
  }
}