import { SwaggerService } from './../../../services/swagger.service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/services/login.service';
import { RouterModule, Router } from '@angular/router';
import { AuthService, Credentials } from 'src/app/core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: string = "";
  password: string = "";
  error: boolean = false;
  public loggedInStatus: number = 0;

  constructor(private loginService: LoginService, 
              private router: Router, 
              private swagger: SwaggerService,
              private authSvc: AuthService) { }

  ngOnInit() {
    this.loginService.loggedIn = false;
  }

  async login() {
    // this.loginService.loggedIn = await this.swagger.admin.apiAdminLoginGet(this.user, this.password).toPromise();
    // if (this.loginService.loggedIn) {
    //   this.router.navigate(['online']);
    // } else {
    //   this.error = true;
    // }
    const credentials: Credentials = {
      username: this.user,
      password: this.password
    }

    try {
      const loggedIn = await this.authSvc.tryWithCredentials(credentials);

      if (loggedIn) {
        this.loggedInStatus = 1
        this.router.navigate(['/online']);
        console.log('route to online players!');
      } else {
        this.loggedInStatus = 2;
      }
    }
    catch (e) {
      this.loggedInStatus = 2;
    }
  }

}
