import { Injectable } from '@angular/core';
import { AdminService, Configuration } from 'src/app/swagger';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwaggerService {

  public admin;

  constructor(protected http: HttpClient) { 
    this.admin = environment.backend;
  }
}
