import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform(futureDate: string): Observable<string> {
    if (!futureDate || this.getMsDiff(futureDate) < 0) {
      return null;
    }

    const source = timer(0, 1000);
    return source.pipe(map((_) => this.msToTime(this.getMsDiff(futureDate))));
    /* .subscribe((timeRemaining) => {
        console.log(timeRemaining);
      }); */

    // return timer(0, 1000).pipe(
    //   map(() => {
    //     // never gets hit
    //       const timeRemaining = this.msToTime(this.getMsDiff(futureDate));
    //       console.log(timeRemaining);
    //       return timeRemaining;
    //   })
    // );
  }

  private getMsDiff = (futureDate: string): number =>
    +new Date(futureDate) - Date.now();

  private msToTime(msRemaining: number): string | null {
    if (msRemaining < 0) {
      console.info('No Time Remaining:', msRemaining);
      return null;
    }

    let seconds: string | number = Math.floor((msRemaining / 1000) % 60),
      minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60),
      hours: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) % 24),
      days: string | number = Math.floor(msRemaining / (1000 * 60 * 60 * 24));

    /**
     * Add the relevant `0` prefix if any of the numbers are less than 10
     * i.e. 5 -> 05
     */
    seconds = seconds < 10 ? '0' + seconds : seconds;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    hours = hours < 10 ? '0' + hours : hours;
    days = days < 10 ? '0' + days : days;
    console.log(days)

    return `${days}`;
  }
}