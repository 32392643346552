import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { GridService } from 'src/services/grid.service';
import { ManageService } from 'src/services/manage.service';
import { ManageAdduserDialogComponent } from './manage-adduser-dialog/manage-adduser-dialog.component';

interface AccessLevelType {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

  gridData: Array<UserInfo> = []
  displayGridData: Array<UserInfo> = []
  private filter = "";
  public hide: boolean = true;
  public displayedColumns: Array<string> = ['szName', 'szPasswd', 'bAuthority', 'action'];

  public newsfeedTypes: AccessLevelType[] = [
    {
      value: 1,
      viewValue: 'Admin'
    }, 
    {
      value: 2,
      viewValue: 'Manager'
    },
    {
      value: 3,
      viewValue: 'Game Master'
    }];

  private sub: Subscription = new Subscription();
  public currentUser: UserInfo = null;

  constructor(public gridService: GridService, 
              private authSvc: AuthService, 
              private manageSvc: ManageService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private router: Router) { }

  ngOnInit() {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(token => token != null)).subscribe(token => {
      this.currentUser = token.userInfo;
      this.gridService.reset();
      this.loadData();
    }));
  }

  async loadData() {
    try {
      const gridData = await this.manageSvc.getAdminUsers().toPromise();
      gridData.forEach(x => x.szPasswd = "");
      this.gridData = gridData;
      this.gridService.pagerHelper.length = this.gridData.length;
      this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
    }
    catch(e) {
      console.error(e);
    }
  }

  public applyFilter(value) {
    this.filter = value;
    this.displayGridData = this.gridService.sliceData(
      this.gridData, this.filter
    )
  }

  public pageChange(event: PageEvent) {
    this.gridService.pagerHelper.pageIndex = event.pageIndex;
    this.gridService.pagerHelper.pageSize = event.pageSize;
    this.displayGridData = this.gridService.sliceData(this.gridData, this.filter);
  }

  public async update(user: UserInfo) {
    try {
      const result = await this.manageSvc.updateAdminUser(user).toPromise();
      if(result) {
        this._snackBar.open(`${user.szName} was successfuly updated!`, '', {duration: 3000, verticalPosition: 'top'});
        user.szPasswd = "";
      }
    }
    catch(e) {
      this._snackBar.open(`Error updating ${user.szName}!`, '', {duration: 3000, verticalPosition: 'top'});
    }
  }

  public async delete(user: UserInfo) {
    try {
      const result = await this.manageSvc.deleteAdminUser(user.szID).toPromise();
      if(result) {
        this.displayGridData = this.gridData.filter(x => x.szID !== user.szID);
        this._snackBar.open(`${user.szName} was successfuly removed!`, '', {duration: 3000, verticalPosition: 'top'});
      }
    }
    catch(e) {
      this._snackBar.open(`Error removing ${user.szName}!`, '', {duration: 3000, verticalPosition: 'top'});
    }
  }

  public openAddUserDialog() {
    const dialogRef = this.dialog.open(ManageAdduserDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed', result);
      this.addUser(result);
    });
  }

  public openLogs() {
    this.router.navigate(['/logs'])
  }

  private async addUser(data: any) {
    try {
      const newUser: UserInfo = {
        szID: data.name,
        szName: data.name,
        bAuthority : data.accessLevel,
        bOPAuthority: 1,
        szPasswd: data.password,
        szOpratorCharID: '1',
        szPhoneNum: '1'
      }
      const result = await this.manageSvc.addAdminUser(newUser).toPromise();
      if(result) {
        this._snackBar.open(`${data.name} has been successfully added!`, '', {duration: 3000, verticalPosition: 'top'});
        this.loadData();
      }
    }
    catch(e) {
      this._snackBar.open(`Error adding new user ${data.name}!`, '', {duration: 3000, verticalPosition: 'top'});
    }
  }
}