import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService, UserInfo } from 'src/app/core/auth.service';
import { LoginService } from 'src/services/login.service';

interface navData {
  name: string;
  href: string;
  icon: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  showFiller = false;

  navData: Array<navData> = [{
    name: 'Online Player',
    href: 'online',
    icon: 'grid_view'
  },{
    name: 'Characters',
    href: 'ban',
    icon: 'people'
  },{
    name: 'Banlist',
    href: 'unban',
    icon: 'gpp_bad'
  },
  {
    name: 'Coupons',
    href: 'coupons',
    icon: 'redeem'
  },
  // {
  //   name: 'Versions',
  //   href: 'versions'
  // },
  {
    name: 'Newsfeed',
    href: 'newsfeed',
    icon: 'article'
  },
  {
    name: 'Server Status',
    href: 'server',
    icon: 'dns'
  }];

  navbarshow: boolean = true;
  public isLoggedIn: boolean;
  private sub: Subscription = new Subscription();
  public currentUser: UserInfo = null;
  public isOpened: boolean = false;

  constructor(private authSvc: AuthService) {
    this.sub.add(this.authSvc.tokenModel$.pipe(filter(x => x != null)).subscribe(u => {
      this.isLoggedIn = true;
      this.currentUser = u.userInfo;
      this.isOpened = true;
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
  }

  toggleNavbar() {
    this.navbarshow = !this.navbarshow;
  }

  public async logout() {
    await this.authSvc.logout();
    this.isLoggedIn = false;
  }

  public getUserRole(): string {
    if(this.currentUser.bAuthority === 1) {
      return 'Admin';
    }
    else if(this.currentUser.bAuthority === 2) {
      return 'Manager';
    }
    else if(this.currentUser.bAuthority === 3) {
      return 'Game Master';
    }
  }
}